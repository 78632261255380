
import axios from 'axios'

function state() {
  return window.__initalizedStore?.store?.getState()
}

function createBaseUrl() {
  const lang = state().language
  let apiBaseUrl = 'http://localhost:8000'
  if (document.getElementById('root')) {
    const apiUrlInDoc = document.getElementById('root').getAttribute('data-api-url')
    apiBaseUrl = apiUrlInDoc !== '##API_URL##' ? apiUrlInDoc : 'http://localhost:8000'
  }
  if (lang) {
    return `${apiBaseUrl}/${lang}`
  } else {
    return `${apiBaseUrl}/nl`
  }
}

export function getEnquete(accessCode) {
  const ie11CacheBust = Date.now()
  return getApi(accessCode, `${/enquete/}?_=${ie11CacheBust}`)
}

export function postResults(accessCode, results) {
  return postApi(accessCode, '/results/', results)
}

export function getFeedback(accessCode) {
  var authOptions = {
    method: 'GET',
    url: createBaseUrl() + '/feedback/',
    headers: {
        'Access-Code-Respondent': accessCode,
    },
  }
  return new Promise((resolve, reject) => {
    return axios(authOptions)
      .then((res) => {
        resolve(res.data)
      }).catch((reason) => reject(reason))
  })
}





function getApi (accessCode, endpoint) {  
    var authOptions = {
      method: 'GET',
      url: createBaseUrl() + endpoint,
      headers: {
          'Access-Code-Enquete': accessCode,

      },
    }
    return new Promise((resolve, reject) => {
      return axios(authOptions)
        .then((res) => {
          resolve(res.data)
        }).catch((reason) => reject(reason))
    })
}

function postApi (accessCode, endpoint, data) {
  

    var authOptions = {
      method: 'POST',
      url: createBaseUrl() + endpoint,
      data: data,
      headers: {
          'Access-Code-Enquete': accessCode,
      },
    }
    return new Promise((resolve, reject) => {
      return axios(authOptions)
        .then((res) => {
          resolve(res.data)
        }).catch((reason) => reject(reason))
    })


}

function putApi (accessCode, endpoint, data) {
  

    var authOptions = {
      method: 'PUT',
      url: createBaseUrl() + endpoint,
      data: data,
      headers: {
          'Access-Code-Enquete': accessCode,
      },
    }
    return new Promise((resolve, reject) => {
      return axios(authOptions)
        .then((res) => {
          resolve(res.data)
        }).catch((reason) => reject(reason))
    })


}



function deleteApi (accessCode, endpoint) {  

    var authOptions = {
      method: 'DELETE',
      url: createBaseUrl() + endpoint,
      headers: {
          'Access-Code-Enquete': accessCode,
      },
    }
    return new Promise((resolve, reject) => {
      return axios(authOptions)
        .then((res) => {
          resolve(res.data)
        }).catch((reason) => reject(reason))
    })


}

