import React, { useEffect, useState, useRef } from 'react'
import { Container, Button, Spinner, Input, Row, Col, Alert } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import actions from '../state/actions'
import { postResults } from '../api/api'
import ViewNavBar from '../elements/ViewNavBar'
import { useHistory, useParams } from 'react-router-dom'
import ProgressBalls from '../elements/ProgressBalls'
import {Link} from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const View03Finish = ({previousTo}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {t} = useTranslation()
  const language = useRef('')
  const [remarks, setRemarks] = useState('')
  const [isSending, setIsSending] = useState(false)
  const [isSent, setIsSent] = useState(false)
  const [hasError, setHasError] = useState(false)  
  const [gdprCode, setGdprCode] = useState('') 
  const scenariosLength = useSelector(state => state.v02Scenarios.scenarios.length)
  const languageInStore = useSelector(state => state.language)
  const params = useParams()
  const savedResults = useSelector(state => state.saved)

  useEffect(() => {
    dispatch(actions.setGeneralSubTitle(''))
  }, [])

  useEffect(() => {
    language.current = languageInStore
  }, [languageInStore])

  useEffect(() => {
    setRemarks(savedResults.respondent.remarks)
  }, [])

  
  useEffect(() => {
    setRemarks(savedResults.respondent.remarks)
  }, [])

  function handleSend() {    
    setHasError(false)
    setIsSending(true)
    postResults(params.access_code, savedResults).then(data => {
      if (data.status === 'ENQUETE_CLOSED') {
        history.push('/gesloten')
      } else { 
        setGdprCode(data.respondentCode)
        dispatch(actions.setPurgeStore())
        dispatch(actions.setLanguage(language.current))
        dispatch(actions.setIsSent(true))
        dispatch(actions.setGdprCode(data.respondentCode))

        setIsSending(false)
        setIsSent(true)     

      }
    }).catch(() => {
      setIsSending(false)
      setHasError(true)
    })
  }



  function handleRemarksChange (e) {
    setRemarks(e.target.value)
  }

  useEffect(() => {
    dispatch(actions.setRespondentRemarks(remarks))
  }, [remarks])

  const feedbackLink = '/feedback/' + gdprCode

  return (
    <div>
      <Container className='pt-5'>
        <ProgressBalls className='mb-4' steps={scenariosLength + 1} currentStep={scenariosLength + 1}/>
        <div className='text-center'>
          {!isSent && <div>
            <h2>{t('ALMOST_FINISHED_TITLE')}</h2>
            <p className='lead mb-5'>{t('ALMOST_FINISHED_BODY')}</p>
            <Row className='mb-5'>
              <Col xs='12' sm={{ size: '6', offset: 3 }} >
                <Input rows='5' type='textarea' onChange={handleRemarksChange} value={remarks} placeholder={t('OPTIONAL_REMARKS')} />
                </Col>
            </Row>
          </div>
          }
          {!isSending && !isSent && <Button size='lg' color='primary' onClick={handleSend}>{t('SUBMIT_ENQUETE')}<FontAwesomeIcon className='ml-3' icon={faChevronRight} /></Button>}
          {isSending && <Spinner color='primary' type='grow' />}
          {hasError &&
            <Row className='mt-3'>
              <Col xs='12' sm={{ size: '6', offset: 3 }} >
                <Alert color='danger' className='mt-2'><small>{t('COULD_NOT_CONNECT_API')}</small></Alert>
              </Col>
            </Row>
          }
          {isSent && <div>
            <h2>{t('THANKS_FOR_SUBMITTING_TITLE')}</h2>
            <p className='mb-5'>{t('THANKS_FOR_SUBMITTING_BODY_1')}</p>             
            <p className='lead'>{t('THANKS_FOR_SUBMITTING_BODY_2')}</p>
          <a href={feedbackLink} className='btn btn-success btn-lg'>{t('YOUR_PERSONAL_PAGE')}{' '}<FontAwesomeIcon icon={faChevronRight}/></a>
          </div>}

        </div>
        {!isSent && <Link to={previousTo} className={'btn btn-outline-secondary btn-sm'}><FontAwesomeIcon className='mr-3' icon={faChevronLeft} />{t('PREVIOUS')}</Link>}
        </Container>
    </div>
  )
}

export default View03Finish
